import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = ({ pageContext }) => {
  console.log(pageContext)
  return (
    <Layout>
      <Seo title={pageContext?.data?.title} />
      <main>
        <section className="page-default">
          <div className="container-fluid">
            <div className="page-default--inner">
              <h1>{pageContext?.data?.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageContext?.data?.data?.content,
                }}
              />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Page
